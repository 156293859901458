import React from "react";
import PropTypes from "prop-types";

const InfoMessage = ({ content }) => {
  return <div className="info-message">{content}</div>;
};
InfoMessage.propTypes = {
  content: PropTypes.string,
};

export default InfoMessage;
