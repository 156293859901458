import React from "react";
import PropTypes from "prop-types";

const ErrorMessage = ({ content }) => {
  return <div className="error-message">{content}</div>;
};

ErrorMessage.propTypes = {
  content: PropTypes.string,
};

export default ErrorMessage;
