import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Heading from "../../components/common/Heading";
import PageHeading from "../../components/common/PageHeading";
import PhotosEnquiryForm from "../../components/forms/PhotosEnquiryForm";
import PhotoGallery from "../../components/photos/PhotoGallery";

const PhotosPage = () => {
  return (
    <Container>
      <PageHeading title="Œuvres" />
      <PhotoGallery />
      <Row>
        <Col xs={12} md={8}>
          <Heading
            size="3"
            title="Si tu as une question par rapport à une photo:"
          />
          <PhotosEnquiryForm />
        </Col>
      </Row>
    </Container>
  );
};

export default PhotosPage;
