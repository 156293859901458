import React from "react";
import PropTypes from "prop-types";

const PageHeading = ({ title }) => {
  return <h1 className="page-heading">{title}</h1>;
};

PageHeading.propTypes = {
  title: PropTypes.string,
};

export default PageHeading;
