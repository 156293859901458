import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import logo from "../../assets/logo.png";

const MainNav = () => {
  const [auth, setAuth] = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const closeMenu = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  function logout() {
    setAuth(null);
    navigate("/");
    closeMenu();
  }
  return (
    <Navbar collapseOnSelect bg="light" expand="lg" expanded={open}>
      <Container>
        <NavLink to="/" onClick={closeMenu}>
          <img src={logo} alt="logo" />
        </NavLink>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setOpen(open ? false : "show")}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            <NavLink to="/" className="navbar-link" onClick={closeMenu}>
              Home
            </NavLink>
            <NavLink to="/photos" className="navbar-link" onClick={closeMenu}>
              Œuvres
            </NavLink>
            <NavLink to="/about" className="navbar-link" onClick={closeMenu}>
              À propos
            </NavLink>
            <NavLink to="/contact" className="navbar-link" onClick={closeMenu}>
              Contact
            </NavLink>
            {auth && (
              <>
                <NavLink
                  to="/admin"
                  className="navbar-link"
                  onClick={closeMenu}
                >
                  Admin
                </NavLink>
                <Button className="navbar-link__btn" onClick={logout}>
                  Déconnexion
                </Button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNav;
