import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "../../hooks/useAxios";
import { Button, Form } from "react-bootstrap";
import ValidationError from "../common/ValidationError";
import FormMessage from "../common/FormMessage";

/**
 * Yup form validation
 * checkbox check: https://stackoverflow.com/questions/67715711/yup-validation-for-multiple-checkboxes
 * additional img check: https://stackoverflow.com/questions/71189522/validating-file-using-react-hook-form-and-yup-x-must-be-a-object-type-but
 */
const schema = yup.object().shape({
  title: yup.string().required("Titre obligatoire"),
  categories: yup.array().min(1).of(yup.string().required()).required(),
  image: yup.mixed().test("image", "image à ajouter", (value) => {
    if (value.length > 0) {
      return true;
    }
    return false;
  }),
});

/**
 *
 * @returns Add new photo component
 */

const AddPhoto = () => {
  const [submitting, setSubmitting] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [message, setMessage] = useState(false);

  const http = useAxios();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  async function onSubmit(inputData) {
    setSubmitting(true);
    setServerError(null);
    console.log(inputData);

    const formData = new FormData();

    const { image, ...data } = inputData;
    formData.append("data", JSON.stringify(data));
    formData.append("files.image", image[0]);

    try {
      await http.post("photographies?populate=*", formData);
      reset({ title: "", categories: "", image: "" });
      setMessage(true);
    } catch (error) {
      setServerError(error.toString());
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {serverError && <ValidationError>{serverError}</ValidationError>}
        {message && <FormMessage content="photo ajoutée" />}

        <Form.Group className="mb-3">
          <Form.Label>Titre</Form.Label>
          <Form.Control {...register("title")} />
          {errors.title && <ValidationError content={errors.title.message} />}
        </Form.Group>

        <Form.Group className="mb-3" controlId="category">
          <Form.Label>Catégorie</Form.Label>

          <Form.Check
            type="checkbox"
            label="gastronomie"
            value="2"
            {...register("categories")}
          />
          <Form.Check
            type="checkbox"
            label="paysage"
            value="3"
            {...register("categories")}
          />
          <Form.Check
            type="checkbox"
            label="immobilier"
            value="1"
            {...register("categories")}
          />
          <Form.Check
            type="checkbox"
            label="personnel"
            value="4"
            {...register("categories")}
          />
          {errors.categories && (
            <ValidationError content="Catégorie(s) à ajouter" />
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Importe une photo</Form.Label>
          <Form.Control type="file" {...register("image")} />
          {errors.image && <ValidationError content={errors.image.message} />}
        </Form.Group>

        <Button variant="primary" type="submit">
          {submitting ? "ajoute..." : "Ajoute"}
        </Button>
      </Form>
    </>
  );
};

export default AddPhoto;
