import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function HomeButton({ content }) {
  return (
    <Link to="/photos" className="btn-home">
      {content}
    </Link>
  );
}

HomeButton.propTypes = {
  content: PropTypes.string,
};

export default HomeButton;
