import React from "react";
import { Image } from "react-bootstrap";
import portrait from "../../assets/images/portrait.jpg";

const PortraitImg = () => {
  return (
    <Image
      src={portrait}
      alt="portrait"
      fluid
      roundedCircle
      className="img-portrait"
    />
  );
};

export default PortraitImg;
