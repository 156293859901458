import React, { useState } from "react";
import { BASE_URL } from "../../constants/api";
import PhotoItem from "./PhotoItem";
import Spinner from "react-bootstrap/Spinner";
import { Col, Row } from "react-bootstrap";
import ErrorMessage from "../common/ErrorMessage";
import useApi from "../../hooks/useApi";
import CategoryFilter from "../ui/CategoryFilter";
import InfoMessage from "../common/InfoMessage";

const url = BASE_URL + "photographies?populate=*";

const PhotoGallery = () => {
  const { data: photos, isLoading, isError } = useApi(url, []);
  const [photoDisplay, setPhotoDisplay] = useState(photos);

  return (
    <>
      <CategoryFilter photos={photos} setPhotoDisplay={setPhotoDisplay} />
      {isLoading && <Spinner />}
      {isError && (
        <ErrorMessage content="Une erreur est survenue, essayez plus tard." />
      )}
      <Row>
        {photoDisplay
          .slice(0)
          .reverse()
          .map((photo) => {
            const alt = photo.title;
            const key = photo.id;
            const id = photo.id;
            const image =
              photo.attributes.image.data.attributes.formats.medium.url;

            return (
              <Col md={12} lg={6}>
                <PhotoItem
                  key={key}
                  id={id}
                  image={image}
                  alt={alt}
                  catID={photo.attributes.categories.data.map((cat) => {
                    return cat.id;
                  })}
                  catName={photo.attributes.categories.data.map((cat) => {
                    return cat.attributes.type;
                  })}
                />
              </Col>
            );
          })}
      </Row>
      {photoDisplay && photoDisplay.length === 0 && (
        <InfoMessage content="Aucune photo trouvée" />
      )}
    </>
  );
};

export default PhotoGallery;
