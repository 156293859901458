import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/layout/Footer";
import MainNav from "./components/layout/MainNav";
import { AuthProvider } from "./context/AuthContext";
import AboutPage from "./pages/about";
import AdminAddPhoto from "./pages/admin/adminAddPhoto";
import AdminHome from "./pages/admin/adminHome";
import ContactPage from "./pages/contact";
import HomePage from "./pages/homepage";
import LoginPage from "./pages/login";
import PhotosPage from "./pages/photos";
import "./sass/styles.scss";
import { Container } from "react-bootstrap";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="page-container">
          <div className="content-wrapper">
            <MainNav />
            <Container>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/photos" element={<PhotosPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/admin" element={<AdminHome />} />
                <Route path="/admin/add" element={<AdminAddPhoto />} />
              </Routes>
            </Container>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
