import React from "react";
import oslo from "../../assets/images/oslo.jpg";
import PageHeading from "../common/PageHeading";
import Paragraph from "../layout/Paragraph";

const HomeImg = () => {
  return (
    <div className="home-banner" style={{ backgroundImage: `url(${oslo})` }}>
      <PageHeading title="Thomas Gasser" />
      <Paragraph content="photographe basé en Suisse romande" />
    </div>
  );
};

export default HomeImg;
