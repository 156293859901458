import React from "react";
import PropTypes from "prop-types";
import { number } from "yup/lib/locale";

const Heading = ({ size = "1", title }) => {
  const VariableHeading = `h${size}`;
  return <VariableHeading>{title}</VariableHeading>;
};

Heading.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
};

export default Heading;
