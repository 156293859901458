import React from "react";
import PropTypes from "prop-types";

const ValidationError = ({ content }) => {
  return <div className="validation-error">{content}</div>;
};

ValidationError.propTypes = {
  content: PropTypes.string,
};

export default ValidationError;
