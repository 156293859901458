import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BASE_URL, TOKEN_PATH } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import ValidationError from "../common/ValidationError";
import AuthContext from "../../context/AuthContext";

const url = BASE_URL + TOKEN_PATH;

const schema = yup.object().shape({
  identifier: yup.string().required("Please enter your username"),
  password: yup.string().required("Please enter your password"),
});

const LoginForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [auth, setAuth] = useContext(AuthContext);

  async function onSubmit(data) {
    setSubmitting(true);
    setLoginError(null);

    console.log(data);

    try {
      const response = await axios.post(url, data);
      setAuth(response.data);
      navigate("/admin");
    } catch (error) {
      console.log("error", error);
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      setLoginError(error.toString());
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {loginError && <ValidationError>{loginError}</ValidationError>}
      <fieldset disabled={submitting}>
        <Form.Group className="mb-3">
          <Form.Label>Nom d'utilisateur</Form.Label>
          <Form.Control {...register("identifier")} />
          {errors.identifier && (
            <ValidationError content={errors.identifier.message} />
          )}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control type="password" {...register("password")} />
          {errors.password && (
            <ValidationError content={errors.password.message} />
          )}
        </Form.Group>

        <Button variant="primary" type="submit">
          {submitting ? "Connexion..." : "Se connecter"}
        </Button>
      </fieldset>
    </Form>
  );
};

export default LoginForm;
