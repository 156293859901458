import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

const AdminNav = () => {
  return (
    <Nav className="justify-content-center admin-nav" activeKey="/home">
      <Nav.Item>
        <NavLink className="admin-navbar__link" to="/admin">
          Bienvenu, Thomas!
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink className="admin-navbar__link" to="/admin/add">
          Ajoute des photos
        </NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default AdminNav;
