import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";

const CategoryFilter = ({ photos, setPhotoDisplay }) => {
  const [currentPhotos, setCurrentPhotos] = useState("All");

  const handleClick = (e) => {
    let word = e.target.value;
    setCurrentPhotos(word);
  };

  /**
   *
   * Filter by category help source
   * https://codesandbox.io/s/admiring-keldysh-dh1o0o?file=/src/App.js:0-2457
   */

  useEffect(() => {
    if (currentPhotos === "All") {
      setPhotoDisplay(photos);
    } else {
      const filtered = photos.filter((photo) => {
        const catType = photo.attributes.categories.data.map((cat) => {
          return cat.attributes.type;
        });
        return catType === currentPhotos || catType.includes(currentPhotos);
      });
      setPhotoDisplay(filtered);
    }
  }, [setPhotoDisplay, currentPhotos, photos]);
  return (
    <div className="btn-group">
      <Button onClick={handleClick} value="All" className="btn-category">
        Voir tout
      </Button>
      <Button
        onClick={handleClick}
        value="gastronomie"
        className="btn-category"
      >
        Gastronomie
      </Button>
      <Button onClick={handleClick} value="paysage" className="btn-category">
        Paysage
      </Button>
      <Button onClick={handleClick} value="immobilier" className="btn-category">
        Immobilier
      </Button>
      <Button onClick={handleClick} value="personnel" className="btn-category">
        Personnel
      </Button>
    </div>
  );
};

export default CategoryFilter;
