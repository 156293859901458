import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import ErrorMessage from "../common/ErrorMessage";

const ShowMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const http = useAxios();

  useEffect(function () {
    async function showMessages() {
      try {
        const response = await http.get("/messages");
        console.log(response.data.data);

        setMessages(response.data.data);
      } catch (error) {
        console.log("error", error);
        setError(error.toString());
      } finally {
        setLoading(false);
      }
    }
    showMessages();
  }, []);
  if (loading) return <Spinner />;

  if (error)
    return <ErrorMessage content="Une erreur est survenue, essaie plus tard" />;
  return (
    <div>
      {messages.map((msg) => {
        const id = msg.id;
        const email = msg.attributes.email;
        const message = msg.attributes.message;
        const date = msg.attributes.createdAt;
        const newDate = new Date(date);
        const formattedDate = newDate.toLocaleDateString();
        return (
          <div className="inbox-msg" key={id}>
            <Row>
              <Col xs={6}>De: {email}</Col>
              <Col xs={6}>Reçu le: {formattedDate}</Col>
            </Row>
            <p>
              Message: <br /> {message}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ShowMessages;
