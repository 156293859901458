import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageHeading from "../../components/common/PageHeading";
import Paragraph from "../../components/layout/Paragraph";
import PortraitImg from "../../components/photos/PortraitImg";

const AboutPage = () => {
  return (
    <Container>
      <PageHeading title="À propos" />
      <Row className="justify-content-md-center">
        <Col xs={12} md={5}>
          <PortraitImg />
        </Col>
        <Col xs={12} md={5}>
          <Paragraph content="Je m'appelle Thomas, photographe basé en Suisse romande. Passionné de photographie, mon studio est à Noville. sinon, on me trouve le plus souvent en montagne, au bord du lac ou à découvrir de nouveaux endroits avec un drone." />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutPage;
