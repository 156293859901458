import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import ValidationError from "../common/ValidationError";
import { BASE_URL } from "../../constants/api";
import FormMessage from "../common/FormMessage";

const url = BASE_URL + "messages";

const schema = yup.object().shape({
  email: yup.string().email().required("Please enter your email"),
  message: yup.string().required("Please enter your message"),
});

const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(inputData) {
    setSubmitting(true);
    setError(null);

    console.log(JSON.stringify({ data: inputData }));
    try {
      const response = await axios.post(url, { data: inputData });
      //console.log("reponse", response.data);
      reset({ email: "", message: "" });
      setMessage(true);
    } catch (error) {
      setError(error.toString());
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {message && <FormMessage content="Merci pour ton message" />}
      {error && (
        <ValidationError content="Une erreur est survenue, essaie plus tard" />
      )}
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control {...register("email")} />
        {errors.email && <ValidationError content={errors.email.message} />}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} {...register("message")} />
        {errors.message && <ValidationError content={errors.message.message} />}
      </Form.Group>

      <Button variant="primary" type="submit">
        Envoyer
      </Button>
    </Form>
  );
};

export default ContactForm;
