import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import ErrorMessage from "../common/ErrorMessage";

const ShowEnquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const http = useAxios();

  useEffect(function () {
    async function fetchEnquiries() {
      try {
        const response = await http.get("/enquiries");
        setEnquiries(response.data.data);
      } catch (error) {
        setError(error.toString());
      } finally {
        setLoading(false);
      }
    }
    fetchEnquiries();
  }, []);
  if (loading) return <Spinner />;

  if (error)
    return <ErrorMessage content="Une erreur est survenue, essaie plus tard" />;
  return (
    <div>
      {enquiries.map((enquiry) => {
        const id = enquiry.id;
        const email = enquiry.attributes.email;
        const message = enquiry.attributes.message;
        const date = enquiry.attributes.createdAt;
        const newDate = new Date(date);
        const formattedDate = newDate.toLocaleDateString();
        return (
          <div className="inbox-msg" key={id}>
            <Row>
              <Col xs={6}>De: {email}</Col>
              <Col xs={6}>Reçu le: {formattedDate}</Col>
            </Row>
            <p>
              Message: <br /> {message}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ShowEnquiries;
