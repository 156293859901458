import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FiInstagram } from "react-icons/fi";
import { CiLogin } from "react-icons/ci";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12} md={4}>
            <div>thomas.gasser81@gmail.com</div>
            <div>+41 77 123 45 67</div>
          </Col>
          <Col xs={12} md={4}>
            <a
              className="footer-icon"
              href="https://www.instagram.com/gasser.thomas/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiInstagram />
            </a>
            <div>Copyright Utv&aelig;r Gasser</div>
          </Col>
          <Col xs={12} md={4}>
            <div>Thomas Gasser</div>
            <NavLink to="/login">
              <CiLogin />
            </NavLink>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
