import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AdminNav from "../../../components/admin/AdminNav";
import ShowEnquiries from "../../../components/admin/ShowEnquiries";
import ShowMessages from "../../../components/admin/ShowMessages";
import Heading from "../../../components/common/Heading";
import PageHeading from "../../../components/common/PageHeading";
import AuthContext from "../../../context/AuthContext";

const AdminHome = () => {
  const [auth] = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth == null) {
      navigate("/");
    }
  }, [navigate, auth]);
  return (
    <>
      <PageHeading title="Espace privé" />
      <AdminNav />
      <Heading size="2" title="Messages reçus" />
      <Row>
        <Col xs={12} md={6}>
          <Heading size="3" title="Contact général" />
          <ShowMessages />
        </Col>
        <Col xs={12} md={6}>
          <Heading size="3" title="Contact photos" />
          <ShowEnquiries />
        </Col>
      </Row>
    </>
  );
};

export default AdminHome;
