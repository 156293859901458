import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PageHeading from "../../components/common/PageHeading";
import LoginForm from "../../components/forms/LoginForm";

const LoginPage = () => {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={5}>
          <PageHeading title="Bienvenu T" />
          <LoginForm />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
