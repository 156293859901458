import React from "react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddPhoto from "../../../components/admin/AddPhoto";
import AdminNav from "../../../components/admin/AdminNav";
import PageHeading from "../../../components/common/PageHeading";
import AuthContext from "../../../context/AuthContext";

const AdminAddPhoto = () => {
  const [auth] = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth == null) {
      navigate("/");
    }
  }, [navigate, auth]);

  return (
    <>
      <AdminNav />
      <PageHeading title="Ajoute une photo à ta collection!" />
      <AddPhoto />
    </>
  );
};

export default AdminAddPhoto;
