import React from "react";
import PropTypes from "prop-types";

const FormMessage = ({ content }) => {
  return <div className="form-message">{content}</div>;
};

FormMessage.propTypes = {
  content: PropTypes.string,
};

export default FormMessage;
