import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PageHeading from "../../components/common/PageHeading";
import ContactForm from "../../components/forms/ContactForm";
import Paragraph from "../../components/layout/Paragraph";

const ContactPage = () => {
  return (
    <Container>
      <PageHeading title="Contact" />
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          <Paragraph content=" Joinable au +41 78 648 36 56," />
          <Paragraph content=" ou par email: thomas.gasser81@gmail.com." />
          <ContactForm />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactPage;
