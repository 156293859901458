import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

const PhotoItem = ({ id, alt, image }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Image fluid src={image} alt={alt} onClick={handleShow} />
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img src={image} alt={alt} className="img-fluid" />
        </Modal.Body>
      </Modal>
    </>
  );
};

PhotoItem.propTypes = {
  id: PropTypes.number,
  alt: PropTypes.string,
  image: PropTypes.string,
};

export default PhotoItem;
